<script>
import affiliatesService from '@/services/affiliates.service';
import Affiliate from '@/data/models/Affiliate'
import EditLayout from '@/layout/EditLayout'
import libraryService from '@/services/library.service'

export default {
  name: 'SingleAffiliate',
  components: {
    EditLayout
  },
  data() {
    return {
      item: new Affiliate,
      photo: {}
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
	mounted() {
    !this.isNew && this.id && this._get()
	},
  methods: {
    _get() {
      affiliatesService.getById({ id: this.id })
        .then(res => {
          this.item = res.data
          res.data.library_id && this._getPhoto({ id: res.data.library_id })
        })
    },
    create() {
      affiliatesService.create({ data: this.item })
      .then(_res => {
        this.$notify({
          message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('affiliate') }),
          icon: "far fa-bell",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success',
        })
        this.$router.push({ name: 'affiliates' })
      });
    },
    edit() {
      affiliatesService.edit({ data: this.item })
      .then(res => {
        this.$notify({
          message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('affiliate') }),
          icon: "far fa-bell",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success',
        })
      });
    },
    _getPhoto({ id }) {
      libraryService.getById({ id })
        .then(res => {
          this.photo = res.data
        })
    },
  }
}
</script>

<template>
  <EditLayout
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    supports-photo
    :supports-editor="false"
    :photo="photo"
    @submit="isNew ? create() : edit()"
  >
    <div slot="middle">
      <BaseInput
        v-model="item.link"
        :label="$t('affiliateLink')"
        :placeholder="$t('enterAffiliateLink')"
      />
    </div>
  </EditLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.editTitle
  margin-bottom: $base-spacing
</style>
