/* eslint-disable camelcase */
export default class Affiliate {
  library_id;
  link;
  name;
  published;

  constructor() {
    this.library_id = null;
    this.link = "";
    this.name = "";
    this.published = 0;
  }
}
